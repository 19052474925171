// Stores related to host functions
// i.e create listing
import { ref } from 'vue';
import { defineStore } from 'pinia';
import cloneDeep from 'lodash.clonedeep';
import { defaultVenueCreate, propertyDefault } from './defaults/host';
export const useHostStore = defineStore('host', () => {
    const changes = ref(false);
    /**
     * If changes are made to the listing but not saved
     * @param boo boolean
     */
    function toggleChanges(boo) {
        changes.value = boo;
    }
    const propertyCreate = ref(propertyDefault);
    function resetProperty() {
        propertyCreate.value = cloneDeep(propertyDefault);
    }
    function updateProperty(data) {
        propertyCreate.value = Object.assign(Object.assign({}, propertyCreate.value), data);
    }
    const flexCreate = ref(defaultVenueCreate);
    function resetFlex() {
        flexCreate.value = cloneDeep(defaultVenueCreate);
    }
    function updateFlex(data) {
        flexCreate.value = Object.assign(Object.assign({}, flexCreate.value), data);
    }
    return {
        changes,
        toggleChanges,
        propertyCreate,
        resetProperty,
        updateProperty,
        flexCreate,
        resetFlex,
        updateFlex
    };
});
