import { computed } from 'vue';
import date from 'quasar/src/utils/date/date.js';
const { getDateDiff } = date;
import { useFlexStore } from 'src/stores/flex';
/**
 * Standardized checks for overlapping timing between selected
 * datetime and the bookings
 */
const CheckBeforeQueryPlugin = () => {
    const flexStore = useFlexStore();
    const consolidatedAvailQueries = computed(() => flexStore.consolidatedFlexAvailQueries);
    /**
     * Standardized checks before querying backend availability.
     *
     * @param key key of the data in consolidatedAvailQueries
     * @returns true if need to requery, false if not
     */
    // define threshold in mins for requery if the data is older
    // than the defined mins
    const requeryThresholdMins = 0;
    function checkBeforeQuery(key) {
        // if key is not inside data
        if (!(key in consolidatedAvailQueries.value)) {
            return true;
        }
        // if version string is not there,
        // it says that there is no data => requery
        // or user indicates to refresh the data => requery
        if (!consolidatedAvailQueries.value[key].version ||
            consolidatedAvailQueries.value[key].to_refresh) {
            return true;
        }
        // if last updated is null, skip the comparing
        if (!consolidatedAvailQueries.value[key].last_updated) {
            return true;
        }
        // no need to requery if last updated is less than the defined threshold
        // should lower this threshold when our order grows
        // only if data is not empty
        if ((consolidatedAvailQueries.value[key].data || []).length > 0 &&
            date.getDateDiff(new Date(), consolidatedAvailQueries.value[key].last_updated, 'seconds') <= requeryThresholdMins * 60) {
            return false;
        }
        return true;
    }
    return {
        checkBeforeQuery
    };
};
export { CheckBeforeQueryPlugin };
