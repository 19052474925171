var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Store for flex type listing page
// and booking related
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLoggingStore } from './logging';
import cloneDeep from 'lodash.clonedeep';
export const useFlexStore = defineStore('flex', () => {
    const loggingStore = useLoggingStore();
    /*******
     * Availability queries
     ******/
    function saveAvailToIdb(data, dbInitialized) {
        return __awaiter(this, void 0, void 0, function* () {
            if (process.env.SERVER || !dbInitialized)
                return;
            const { openDB } = yield import('idb');
            const db = yield openDB('get_tech');
            try {
                for (let i = 0; i < data.length; i++) {
                    const tx = db.transaction('consolidated-flex-availability', 'readwrite');
                    yield Promise.all([
                        tx.store.put(data[i]),
                        tx.done
                    ]);
                }
            }
            catch (error) {
                loggingStore.errorHandler(error);
            }
        });
    }
    const consolidatedFlexAvailQueries = ref({});
    function consolidatedAvailQuery(data, dbInitialized) {
        return __awaiter(this, void 0, void 0, function* () {
            const commitDict = {};
            const txObjList = [];
            for (const key in data) {
                const object = Object.assign(Object.assign({}, data[key]), { 
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    data: data[key].data
                        ? JSON.parse(JSON.stringify(data[key].data)) : '', key: key, listing_id: Number(data[key].listing_id), last_updated: data[key].last_updated || new Date(), version: data[key].version });
                commitDict[key] = Object.assign({}, object);
                // for data to be saved to idb
                // save only ones with data populated
                if (!object.data)
                    continue;
                // must clone object to be saved
                txObjList.push(cloneDeep(object));
            }
            yield saveAvailToIdb(txObjList, dbInitialized);
            consolidatedFlexAvailQueries.value = Object.assign(Object.assign({}, consolidatedFlexAvailQueries.value), commitDict);
        });
    }
    /*******
     * Booking related
     ******/
    const booking = ref([{
            date: '',
            from_time: '',
            to_time: '',
            available_capacity: 0,
            set_index: 0,
            quantity: ''
        }]);
    /**
     * Set booking timings
     * @param bookingData booking timings
     */
    function setBooking(bookingData) {
        booking.value = bookingData;
    }
    /**
     * Clear booking timing
     */
    function clearTimeslots() {
        booking.value = [{
                date: '',
                from_time: '',
                to_time: '',
                set_index: 0,
                available_capacity: 0,
                quantity: ''
            }];
    }
    // listing
    const listing = ref(null);
    function saveListingToIdb(listingData, dbInitialized) {
        return __awaiter(this, void 0, void 0, function* () {
            if (process.env.SERVER || !dbInitialized)
                return;
            if (listingData.disableDbSave && ((listingData.revision || 0) < 2))
                return;
            const { openDB } = yield import('idb');
            const db = yield openDB('get_tech');
            const tx = db.transaction('venue', 'readwrite');
            try {
                yield Promise.all([
                    tx.store.put(Object.assign(Object.assign({}, listingData), { key: listingData.id, last_updated: new Date() })),
                    tx.done
                ]);
            }
            catch (error) {
                loggingStore.errorHandler(error);
            }
        });
    }
    /**
     * save listing to store and IDB for easy access
     * @param listingObj listing data
     * @param dbInitialized idb initialized
     */
    function saveListing(listingObj, dbInitialized = false) {
        if (listingObj === null) {
            listing.value = listingObj;
            return;
        }
        if (!('disableDbSave' in listingObj)) {
            listingObj.disableDbSave = false;
        }
        if ('id' in listingObj) {
            listingObj.id = Number(listingObj.id);
        }
        listing.value = listingObj;
        void saveListingToIdb(listingObj, dbInitialized);
    }
    /**
     * Toggle if listing is favourited
     * @param favId if listing is favourited
     */
    function setListingFav(favId) {
        const listingCopy = Object.assign({}, listing.value);
        listingCopy.is_fav = favId;
        // @ts-expect-error ignore
        listing.value = listingCopy;
    }
    /**
     * Clear listing
     */
    function clearListing() {
        listing.value = null;
    }
    // misc
    const misc = ref({
        order_type: 'normal',
        purpose: '',
        remark: '',
        pax: ''
    });
    /**
     * Set booking details for booking
     * @param miscData booking details
     */
    function setMisc(miscData) {
        misc.value = Object.assign(Object.assign({}, misc.value), miscData);
    }
    /**
     * Clear booking details
     */
    function clearMisc() {
        misc.value = {
            order_type: 'normal',
            purpose: '',
            remark: '',
            pax: ''
        };
    }
    const billing = ref({
        card_id: null,
        billing_address_country: null,
        billing_address_line1: null,
        billing_address_zip: null,
        billing_name: null
    });
    /**
     * Set phone details of the viewer
     * @param data phone details
     */
    function setBilling(billingData) {
        billing.value = Object.assign(Object.assign({}, billing.value), billingData);
    }
    function clearBilling() {
        billing.value = {
            card_id: null,
            billing_address_country: null,
            billing_address_line1: null,
            billing_address_zip: null,
            billing_name: null
        };
    }
    const redeemedPoints = ref(null);
    /**
     * Set points to redeem
     * @param points points to redeem
     */
    function setRedeemPoints(points) {
        redeemedPoints.value = points;
    }
    const selectedAddons = ref([]);
    function saveAddons(data) {
        selectedAddons.value = data;
    }
    function removeAddons(index) {
        const selectedAddonsCopy = [...selectedAddons.value];
        selectedAddonsCopy.splice(Number(index), 1);
        selectedAddons.value = selectedAddonsCopy;
    }
    const selectedSku = ref(null);
    /**
     * Select sku inside listing page
     * @param skuData sku data
     */
    function setSku(skuData, disableClear = false) {
        selectedSku.value = Object.assign(Object.assign({}, (selectedSku.value || {})), skuData);
        if (disableClear)
            return;
        clearTimeslots();
    }
    /*******
     * Listing Page
     ******/
    const bookingDialog = ref(false);
    /**
     * Show popup to create booking on mobile
     * @param boo boolean
     */
    function toggleBookingDialog(boo) {
        bookingDialog.value = boo;
    }
    // view
    const listingDefaultOpen = ref([]);
    /**
     * Set sections that are opened by default
     * @param data sections that are open by default
     */
    function setListingDefaultOpen(data) {
        listingDefaultOpen.value = data;
    }
    // sections that are not expandable item
    const listingDisableSection = ref([]);
    /**
     * Set sections that are not expandable item
     * @param data sections that are not not expandable item
     */
    function setListingDisableSection(data) {
        listingDisableSection.value = data;
    }
    // fees section that are used in the listing page
    const feesData = ref(null);
    function setFeesData(data) {
        feesData.value = data;
    }
    const feesError = ref(null);
    function setFeesError(data) {
        feesError.value = data;
    }
    const feesLoading = ref(false);
    function setFeesLoading(data) {
        feesLoading.value = data;
    }
    /*******
     * Host custom order
     ******/
    const customListing = ref(null);
    /**
     * save listing to store and IDB for easy access
     * @param listingObj listing data
     * @param dbInitialized idb initialized
     */
    function saveCustomListing(listingObj, dbInitialized = false) {
        if (!listingObj) {
            customListing.value = listingObj;
            return;
        }
        if (!('disableDbSave' in listingObj)) {
            listingObj.disableDbSave = false;
        }
        if ('id' in listingObj) {
            listingObj.id = Number(listingObj.id);
        }
        customListing.value = listingObj;
        void saveListingToIdb(listingObj, dbInitialized);
    }
    const customOrderDialog = ref(false);
    /**
     * toggle custom order dialog
     * @param boo boolean
     */
    function setCustomOrderDialog(boo) {
        customOrderDialog.value = boo;
    }
    // custom price
    const appliedOffer = ref(null);
    /**
     * Set custom rate according to the selected sku
     * @param priceVal new rate
     */
    function setPrice(priceVal) {
        appliedOffer.value = priceVal === null || priceVal === ''
            ? null
            : Number(priceVal);
    }
    /**
     * Clear all data
     */
    function clearAll() {
        clearTimeslots();
        clearMisc();
        clearBilling();
        selectedAddons.value = [];
        appliedOffer.value = null;
        redeemedPoints.value = null;
        selectedSku.value = null;
    }
    return {
        consolidatedFlexAvailQueries,
        consolidatedAvailQuery,
        booking,
        setBooking,
        clearTimeslots,
        misc,
        setMisc,
        clearMisc,
        billing,
        setBilling,
        clearBilling,
        redeemedPoints,
        setRedeemPoints,
        selectedAddons,
        saveAddons,
        removeAddons,
        selectedSku,
        setSku,
        listing,
        saveListing,
        clearListing,
        setListingFav,
        bookingDialog,
        toggleBookingDialog,
        listingDefaultOpen,
        setListingDefaultOpen,
        listingDisableSection,
        setListingDisableSection,
        feesData,
        setFeesData,
        feesError,
        setFeesError,
        feesLoading,
        setFeesLoading,
        customListing,
        saveCustomListing,
        customOrderDialog,
        setCustomOrderDialog,
        appliedOffer,
        setPrice,
        clearAll
    };
});
