import { v4 as uuidv4 } from 'uuid';
const defaultVenueCreate = {
    s3_unique_identifier: uuidv4(),
    country: {
        id: 1,
        country_code: '+65',
        name: 'Singapore',
        pytz_timezone: 'Asia/Singapore',
        timezone: '+0800',
    },
    condition: 'fitted',
    listing_type: 'commercial_rent_part_time',
    space_charge_type: 'normal',
    block: '',
    floor: '',
    postal: '',
    location: [],
    street: '',
    unit: '',
    desc: '',
    house_rule: '',
    min_pax: null,
    max_pax: null,
    name: '',
    size: null,
    hourly_rate: null,
    daily_rate: null,
    monthly_rate: null,
    space_moq_detail: {
        moq_mon: '',
        moq_tue: '',
        moq_wed: '',
        moq_thu: '',
        moq_fri: '',
        moq_sat: '',
        moq_sun: '',
    },
    space_amenities_tag_set: [],
    space_operating_hour_set: [],
    space_peak_surcharge_set: [],
    space_purpose_tag_set: [],
    space_image_set: [],
    space_refund_if_less_equal_7_days_pct: '0.2',
    space_refund_if_more_than_7_less_equal_30_days_pct: '0.5',
    space_refund_if_more_than_30_less_equal_60_days_pct: '0.8',
    space_refund_if_more_than_60_days_pct: '1',
};
const propertyDefault = {
    block: null,
    street: null,
    floor: null,
    unit: null,
    postal: null,
    location: [],
    s3_unique_identifier: uuidv4(),
    showcase_img_set: [],
    country: null,
    condition: null,
    desc: null,
    listing_type: null,
    name: null,
    price: null,
    size: null,
    property_purpose_set: [],
    property_type_set: null,
    property_amenities_set: [],
    advert_tag_set: [],
    space_ad_operating_hour_set: []
};
export { propertyDefault, defaultVenueCreate };
