// Store to centralize storage of some height
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useSizeStore = defineStore('size', () => {
    const headerHeight = ref(0);
    /**
     * @param num height of the header
     */
    function setHeaderHeight(num) {
        headerHeight.value = num;
    }
    const footerHeight = ref(0);
    /**
     * @param num height of the footer
     */
    function setFooterHeight(num) {
        footerHeight.value = num;
    }
    const innerHeight = ref(0);
    /**
     * @param num height of the page
     */
    function setInnerHeight(num) {
        innerHeight.value = num;
    }
    const innerWidth = ref(0);
    /**
     * @param num width of the page
     */
    function setInnerWidth(num) {
        innerWidth.value = num;
    }
    return {
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        innerHeight,
        setInnerHeight,
        innerWidth,
        setInnerWidth,
    };
});
