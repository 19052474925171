import { useAccountStore } from "./stores/account";
import { useCentralStore } from "./stores/central";
import { useLoggingStore } from "./stores/logging";
import { useThreadingStore } from "./stores/threading";
import { defineComponent, computed, watch, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash.clonedeep";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { AppBaseMetaPlugin } from 'src/layouts/main/methods/baseMeta';
import { FlexCentralQueryPlugin } from 'src/features/datetime/backendAvailability/methods/flexCentralQuery';
import { InitializePlugin } from "src/layouts/main/methods/initialize";
import { LogoutFunctionsPlugin } from "src/features/account/methods/logout";
import { RedirectPlugin } from 'src/layouts/main/methods/redirect';
import { MetaPlugin } from "src/plugins/meta/generalMeta";
import { StandardStylePlugin } from 'src/layouts/main/methods/standardStyle';
import { StandardScriptPlugin } from 'src/layouts/main/methods/scripts';
import { AxiosDataParser } from "src/plugins/axios/axiosParser";
import { SsrStoreHydrationPlugin } from "./stores/plugin/localHydration";
import { GoogleAnalyticsTrack } from "src/plugins/google/analytics";
import { AppIndexDB } from "src/layouts/main/methods/appIndexDb";
export default defineComponent({
    name: "App",
    preFetch({ currentRoute }) {
        if (!process.env.SERVER)
            return;
        /*****
         * Custom function for Number types
         *****/
        Number.prototype.round = function (places) {
            const rounded = 
            // @ts-expect-error ignore
            Math.floor((this * Math.pow(10, places + 2)) / 10) /
                Math.pow(10, places + 1);
            // @ts-expect-error ignore
            return +(Math.round(rounded + "e+" + places) + "e-" + places);
        };
        /*****
         * Check if should load google map script immediately
         *****/
        const typedCurrentRoute = currentRoute.name;
        if (!typedCurrentRoute)
            return;
        /*****
         * Preload appropriate css files according
         * to profile role
         *****/
        const accountStore = useAccountStore();
        const loggingStore = useLoggingStore();
        return new Promise(resolve => {
            const axiosParser = new AxiosDataParser({
                url: `https://${process.env.domain}/css-manifest.json`,
                type: 'list',
                handleSelf: true,
                state: 'manifest',
                alert: false,
                loading: false
            }, {});
            resolve(axiosParser.backendCall());
        }).then((axiosData) => {
            const { success, data: manifest } = axiosData;
            if (!success)
                return;
            let isHost = false;
            const user = accountStore.user;
            if (user) {
                if (user.profile_role_detail) {
                    const hostRole = user.profile_role_detail.filter(items => {
                        return items.role_name === 'host';
                    });
                    isHost = (hostRole.length > 0);
                }
            }
            const link = cloneDeep(metaStore.link);
            for (const key in manifest) {
                // preload host css if user is host
                if (isHost && (/^host/.test(key))) {
                    link[`host-css-preload-${key}`] = {
                        rel: 'preload',
                        as: 'style',
                        href: manifest[key],
                        id: `host-css-preload-${key}`
                    };
                    continue;
                }
                if (!(/^user/.test(key)))
                    continue;
                // preload user css
                link[`user-css-preload-${key}`] = {
                    rel: 'preload',
                    as: 'style',
                    href: manifest[key],
                    id: `user-css-preload-${key}`
                };
            }
            metaStore.replaceLink(link);
        }).catch((err) => {
            loggingStore.errorHandler(err);
        });
    },
    setup() {
        const $q = useQuasar();
        // initialize stores
        SsrStoreHydrationPlugin();
        const centralStore = useCentralStore();
        const threadingStore = useThreadingStore();
        // initialize meta info
        MetaPlugin();
        // after which, initialize plugins
        AppBaseMetaPlugin();
        LogoutFunctionsPlugin();
        RedirectPlugin();
        StandardScriptPlugin();
        StandardStylePlugin();
        FlexCentralQueryPlugin();
        /*****
         * Initialize user data
         *****/
        InitializePlugin();
        /*****
         * If app is online, push the threading runs to continue
         *****/
        const isOnline = computed(() => centralStore.online);
        watch(isOnline, (newValue) => {
            if (!newValue)
                return;
            threadingStore.nextInQueue();
        });
        const { openIndexDB } = AppIndexDB();
        const { userTagging } = GoogleAnalyticsTrack();
        onMounted(() => {
            userTagging();
            openIndexDB();
            // set pool size for threading
            if (typeof navigator !== "undefined") {
                if (typeof navigator.hardwareConcurrency !== "undefined") {
                    threadingStore.setPoolSize(navigator.hardwareConcurrency * 2);
                }
            }
            // set unique identifier for this browser
            // for tagging
            const bId = $q.platform.is.capacitor
                ? $q.localStorage.getItem("bId")
                : $q.cookies.get("bId");
            if (!bId) {
                if ($q.platform.is.capacitor) {
                    void $q.localStorage.set("bId", uuidv4());
                }
                else {
                    void $q.cookies.set("bId", uuidv4(), {
                        expires: "99999d",
                        path: "/",
                        secure: false,
                    });
                }
            }
            // after all are done
            centralStore.toggleMounted(true);
        });
    },
});
