export default {
  "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "About This Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About This Space"])},
  "Suitable For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable For"])},
  "Includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes"])},
  "All timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All timings are in accordance to"])},
  "Timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timings are in accordance to"])},
  "Not available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])}
}