export default {
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "Verify Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Phone Number"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "Country Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "See Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Less"])},
  "Show more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "Show less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "Show Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Less"])},
  "Show All": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show All (", _interpolate(_named("count")), " more)"])},
  "View Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
  "Go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
  "Credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
  "Cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
  "Promo code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo code"])},
  "Browse Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Spaces"])},
  "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space"])},
  "Message us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message us"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "dashboard": {
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "About me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
    "No resources available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No resources available"])},
    "All values shown below are in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All values shown below are in ", _interpolate(_named("currency"))])}
  },
  "form": {
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Select Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Day"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "Start Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "Select Date Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date Range"])},
    "Preferred Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Start Date"])},
    "End Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "Select Lease Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Lease Start"])},
    "Select Lease End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Lease End"])},
    "Cut off date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut off date"])},
    "Add a Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Day"])},
    "Add a Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Month"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "Preferred move in date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred move in date"])},
    "For recurring bookings, click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For recurring bookings, click"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])}
  },
  "order": {
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIONS"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "View Listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Listing"])},
    "View Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Property"])},
    "View in Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in Maps"])},
    "View in Google Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in Google Maps"])},
    "View Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Receipt"])},
    "Add to Google Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Google Calendar"])},
    "Payment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Host Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host Remarks"])}
  },
  "pages": {
    "Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])}
  },
  "size": {
    "Sq Ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sq Ft"])},
    "Sq M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sq M"])}
  },
  "user": {
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "Account pending deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account pending deletion"])},
    "Verify Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Email"])},
    "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Sign Up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "Joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined"])},
    "Tell us more about yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us more about yourself"])}
  }
}