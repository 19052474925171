export default {
  "mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "psf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每平方英尺"])},
  "Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业"])},
  "Residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅"])},
  "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房源"])},
  "Rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["租"])},
  "Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
  "Preferred viewing timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看房时间"])},
  "Request to view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约看房"])},
  "Request purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地方用途"])},
  "More Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多空间"])},
  "Return to search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回搜索页面"])},
  "content": {
    "Last updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新"])},
    "Preferred Viewing Timings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看房时间"])}
  }
}