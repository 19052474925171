import { ref } from 'vue';
import { filter, tap, map } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { CheckBeforeQueryPlugin } from './checkBeforeQuery';
import { ThreadingQueryPlugin } from 'src/features/serverConnector/threading';
import { computed, watch } from 'vue';
import { useCentralStore } from 'src/stores/central';
import { useFlexStore } from 'src/stores/flex';
import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
/**
 * Centralized query for flex availability
 * for new endpoints
 */
const FlexCentralQueryPlugin = () => {
    const centralStore = useCentralStore();
    const flexStore = useFlexStore();
    const consolidatedAvailQueries = computed(() => flexStore.consolidatedFlexAvailQueries);
    const isOnline = computed(() => centralStore.online);
    const { checkBeforeQuery } = CheckBeforeQueryPlugin();
    /**
     * Put the data to be queried into a queue
     */
    const queryQueue = ref([]);
    const queryKeyQueue = computed(() => {
        return queryQueue.value.map((item) => {
            return item.key;
        });
    });
    const { subject: consolidatedQuery$ } = rxInitSubjectCallback();
    useObservable(consolidatedQuery$.pipe(tap((v) => {
        const newToQueue = [];
        for (const key in v) {
            if (v[key].version && !v[key].to_refresh)
                continue;
            if (queryKeyQueue.value.indexOf(key) > -1)
                continue;
            newToQueue.push(v[key]);
        }
        queryQueue.value = [
            ...cloneDeep(queryQueue.value),
            ...newToQueue
        ];
    })));
    watch(consolidatedAvailQueries, (newValue) => {
        consolidatedQuery$.next(newValue);
    }, { immediate: true });
    const querying = ref(false);
    /**
     * When data is returned from backend,
     * update the store, idb => then remove the current
     * key from queue
     */
    function threadingAvailCallback({ newValue }) {
        // check if queue is empty
        // prevent misfires
        if (queryQueue.value.length === 0) {
            querying.value = false;
            return;
        }
        const firstInQueueVal = queryQueue.value[0];
        // check if space id matches current queue
        if (Number(firstInQueueVal.listing_id) !== Number(newValue.space_id))
            return;
        // check if avail details matches current queue
        const queueDataKey = `space_by_${firstInQueueVal.type}_avai_detail`;
        if (!(queueDataKey in newValue))
            return;
        /**
         * remove query from queue so that the next query can
         * start
         */
        const queryQueueCopy = [...queryQueue.value];
        const queryIndex = queryQueueCopy.findIndex((item) => {
            return item.key === newValue.queryKey;
        });
        if (queryIndex === -1)
            return;
        const queueData = queryQueueCopy.splice(queryIndex, 1)[0];
        // save updated data to store and idb
        void flexStore.consolidatedAvailQuery({
            [queueData.key]: Object.assign(Object.assign({}, queueData), { to_refresh: false, 
                // @ts-expect-error ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                data: newValue[queueDataKey], version: uuidv4() })
        }, centralStore.dbInitialized);
        querying.value = false;
        queryQueue.value = queryQueueCopy;
    }
    function threadingAvailErrorCallback(err) {
        // prevent misfires
        if (queryQueue.value.length === 0) {
            querying.value = false;
            return;
        }
        // remove from queue if its not 408
        const typedErr = err;
        if (typedErr.code !== 408 && isOnline.value) {
            querying.value = false;
            // when error, remove from queue
            const queryQueueCopy = [...queryQueue.value];
            const firstInQueue = queryQueueCopy.splice(0, 1)[0];
            // save updated data to store and idb
            if (typedErr.code !== 400) {
                void flexStore.consolidatedAvailQuery({
                    [firstInQueue.key]: Object.assign(Object.assign({}, firstInQueue), { to_refresh: true, 
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        data: [], version: uuidv4() })
                }, centralStore.dbInitialized);
            }
            queryQueue.value = queryQueueCopy;
        }
        else {
            querying.value = false;
        }
    }
    function skipQueryToNext() {
        // prevent misfires
        if (queryQueue.value.length === 0) {
            querying.value = false;
            return;
        }
        // skip query to backend
        const queryQueueCopy = [...queryQueue.value];
        queryQueueCopy.shift();
        queryQueue.value = queryQueueCopy;
        querying.value = false;
    }
    /**
     * Send data to backend by queue
     */
    const { threadingTaskFunction } = ThreadingQueryPlugin('consolidatedAvail', threadingAvailCallback, threadingAvailErrorCallback, false);
    const urlList = {
        'user': 'user_manage_space_2',
        'host': 'host_manage_space_2',
        'admin': 'admin_manage_space_2'
    };
    const { subject: queryQueue$ } = rxInitSubjectCallback();
    useObservable(queryQueue$.pipe(filter(() => !querying.value), filter(() => queryQueue.value.length > 0), tap(() => {
        querying.value = true;
    }), map(() => queryQueue.value[0]), filter(({ key }) => {
        const toQuery = checkBeforeQuery(key);
        if (!toQuery)
            skipQueryToNext();
        return toQuery;
    }), tap((queueVal) => {
        threadingTaskFunction({
            data: {
                url: `${urlList[queueVal.user_type]}/${queueVal.listing_id}/view_space_avai`,
                data: Object.assign({ frequency: queueVal.type, avai_date: queueVal.query_date }, queueVal.additionalParams),
                queryKey: queueVal.key,
                type: 'update',
                unrestricted: true,
                alert: false,
                loading: false
            },
            type: 'api'
        });
    })));
    watch(isOnline, (newValue, oldValue) => {
        if (!newValue)
            return;
        if (oldValue)
            return;
        queryQueue$.next('');
    });
    watch(queryQueue, () => {
        queryQueue$.next('');
    });
};
export { FlexCentralQueryPlugin };
