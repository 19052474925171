var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { of } from 'rxjs';
import { filter, catchError, switchMap, take, tap } from 'rxjs/operators';
import { useCentralStore } from 'src/stores/central';
import { useLoggingStore } from 'src/stores/logging';
import { openDB } from 'idb';
import date from 'quasar/src/utils/date/date.js';
const { addToDate } = date;
/**
 * Standard method to initialize indexedDB for app
 * usage
 */
const AppIndexDB = () => {
    const centralStore = useCentralStore();
    const loggingStore = useLoggingStore();
    const storeIndexes = {
        'consolidated-flex-availability': [{
                key: ['key'],
                name: 'by-key',
                unique: true
            }, {
                key: ['listing_id'],
                name: 'by-listing_id',
                unique: false
            }, {
                key: ['query_date'],
                name: 'by-query_date',
                unique: false
            }, {
                key: ['type'],
                name: 'by-type',
                unique: false
            }, {
                key: ['user_type'],
                name: 'by-user_type',
                unique: false
            }, {
                key: ['last_updated'],
                name: 'by-last_updated',
                unique: false
            }],
        'venue': [{
                key: ['id'],
                name: 'by-id',
                unique: true
            }, {
                key: ['last_updated'],
                name: 'by-last_updated',
                unique: false
            }],
        'prop-listing': [{
                key: ['id'],
                name: 'by-id',
                unique: true
            }, {
                key: ['last_updated'],
                name: 'by-last_updated',
                unique: false
            }]
    };
    const storeList = Object.keys(storeIndexes);
    function parseIndexDB() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const dbStore = yield openDB('get_tech');
                const keyRangeValue = IDBKeyRange.upperBound(date.addToDate(new Date(), { days: -5 }));
                const transaction = dbStore.transaction(storeList, 'readonly');
                const delDict = {};
                storeList.forEach(item => {
                    delDict[item] = [];
                });
                for (let i = 0; i < storeList.length; i++) {
                    const objectStore = transaction.objectStore(storeList[i]);
                    const index = objectStore.index('by-last-updated');
                    let cursor = yield index.openCursor(keyRangeValue);
                    while (cursor) {
                        delDict[storeList[i]].push(cursor.primaryKey);
                        cursor = yield cursor.continue();
                    }
                }
                for (const key in delDict) {
                    const deleteDictList = delDict[key];
                    if (deleteDictList.length > 0) {
                        const objStore = dbStore.transaction(key, 'readwrite').objectStore(key);
                        for (let i = 0; i < deleteDictList.length; i++) {
                            void objStore.delete(deleteDictList[i]);
                        }
                    }
                }
            }
            catch (error) {
                loggingStore.errorHandler(error);
            }
        });
    }
    /**
     * Get store from IDB
     * @param db IDB
     * @param transaction IDB transaction
     * @param storeName IDB store name
     * @returns IDB store
     */
    function getStore(db, transaction, storeName) {
        let store;
        if (!db.objectStoreNames.contains(storeName)) {
            store = db.createObjectStore(storeName, {
                keyPath: 'key'
            });
        }
        else {
            store = transaction.objectStore(storeName);
        }
        return store;
    }
    /**
     * Create indexes in iDB
     * @param store IDB store
     * @param indexNames index name to create
     */
    function createIndexes(store, indexNames) {
        for (let i = 0; i < indexNames.length; i++) {
            if (!store.indexNames.contains(indexNames[i].name)) {
                store.createIndex(indexNames[i].name, indexNames[i].key, {
                    unique: indexNames[i].unique
                });
            }
        }
        if (!store.indexNames.contains('by-last-updated')) {
            store.createIndex('by-last-updated', 'last_updated', {
                unique: false
            });
        }
    }
    /**
     * Initialize indexedDB
     */
    function openIndexDB() {
        of('').pipe(switchMap(() => {
            if ((typeof window === 'undefined') || !('indexedDB' in window)) {
                return of('');
            }
            return openDB('get_tech', 11, {
                upgrade(db, oldVersion, newVersion, transaction) {
                    for (let i = 0; i < storeList.length; i++) {
                        const storeName = storeList[i];
                        if (storeName in storeIndexes) {
                            const store = getStore(db, transaction, storeName);
                            createIndexes(store, storeIndexes[storeName]);
                        }
                    }
                }
            });
        }), catchError((err) => {
            loggingStore.errorHandler(err);
            return of('');
        }), filter(v => v !== ''), tap(() => {
            centralStore.toggleDbInitialized(true);
        }), take(1)).subscribe(() => {
            void parseIndexDB();
        });
    }
    return {
        openIndexDB
    };
};
export { AppIndexDB };
