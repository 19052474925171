export default {
  "Flexible Rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Rental"])},
  "Flex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flex"])},
  "Responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Responds in ", _interpolate(_named("hours")), " hr(s)"])},
  "Typically responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Typically responds in ", _interpolate(_named("hours")), " hr(s)"])},
  "Full Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Space"])},
  "Coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking"])},
  "Instant Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Confirm"])},
  "Payment Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
  "Hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
  "Hourly pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly pass"])},
  "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "Flex Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day/Mth Pass"])},
  "Day-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Range"])},
  "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "Month Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Pass"])},
  "Month pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month pass"])},
  "pax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pax"])},
  "ENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENDED"])},
  "UPCOMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPCOMING"])},
  "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCELED"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
  "canceled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled Expired"])},
  "canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled By Admin"])},
  "canceled_by_guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled By Guest"])},
  "canceled_by_guest_wif_things_considered_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled By Guest"])},
  "canceled_by_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled By Host"])},
  "charge_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Failed"])},
  "charge_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Processing"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hr"])},
  "hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hrs"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "mth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mth"])}
}