/**
 * Combine host page and private page
 * to get full list of private pages
 * @returns private page list
 */
function parsePrivatePages() {
    let privatePages = JSON.parse(process.env.private_pages);
    const hostPages = JSON.parse(process.env.host_pages);
    privatePages = privatePages.concat(hostPages);
    return {
        hostPages,
        privatePages,
    };
}
export { parsePrivatePages };
