import { computed } from 'vue';
import { useCentralStore } from 'src/stores/central';
import { dtLocaleIntPlugin } from 'src/plugins/i18n/datetime/datetime';
const selectedLocalePlugin = () => {
    const centralStore = useCentralStore();
    const { locale } = dtLocaleIntPlugin();
    const dtLocale = computed(() => locale[centralStore.locale || 'en-US']);
    return {
        dtLocale
    };
};
export { selectedLocalePlugin };
