export default {
  "mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan"])},
  "psf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per kaki persegi"])},
  "Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komersial"])},
  "Residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumah Tinggal"])},
  "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properti"])},
  "Rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sewa"])},
  "Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jual"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli"])},
  "Preferred viewing timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Preferensi untuk Melihat"])},
  "Request to view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan untuk Melihat"])},
  "Request purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya tertarik untuk menggunakan tempat ini untuk"])},
  "More Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebih Banyak Ruang"])},
  "Return to search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Pencarian"])},
  "content": {
    "Last updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terakhir diperbarui"])},
    "Preferred Viewing Timings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Preferensi untuk Melihat"])}
  }
}