export default {
  "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
  "About This Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang Ruang Ini"])},
  "Suitable For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocok Untuk"])},
  "Includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termasuk"])},
  "All timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua waktu mengacu pada"])},
  "Timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu mengacu pada"])},
  "Not available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak tersedia"])}
}