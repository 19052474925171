// Meta store
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useLoggingStore = defineStore('logging', () => {
    const errors = ref([]);
    /**
     * Centralized error handling. To add logging to sentry later
     * @param errorData any data
     */
    function errorHandler(errorData) {
        /**
         if (
            errorData.message &&
            errorData.message.toLowerCase().indexOf('permission') > -1
          ) {
            if (!(context.rootState.account.user)) {
              void context.dispatch('central/requireLogin', {
                required: true
              })
            }
          }
         */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        errors.value = [...errors.value, errorData];
    }
    return {
        errors,
        errorHandler,
    };
});
