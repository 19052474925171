export default {
  "mo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mo"])},
  "psf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["psf"])},
  "Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
  "Residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential"])},
  "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties"])},
  "Rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
  "Sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "Preferred viewing timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred viewing timing"])},
  "Request to view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request to view"])},
  "Request purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm interested to use this place for"])},
  "More Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Spaces"])},
  "Return to search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to search"])},
  "content": {
    "Last updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
    "Preferred Viewing Timings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Viewing Timings"])}
  }
}