import { computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { timer } from 'rxjs';
import { debounce, debounceTime, tap, } from 'rxjs/operators';
import { rxInitSubjectCallback, useObservable } from 'src/plugins/rxjs/base';
import { useAccountStore } from 'src/stores/account';
import { useCentralStore } from 'src/stores/central';
import { useViewStore } from 'src/stores/view';
import { AppGeneralVariables } from 'src/plugins/general/variables';
import { parsePrivatePages } from 'src/plugins/general/privatePages';
/**
 * Redirect users accordingly
 */
const RedirectPlugin = () => {
    const $q = useQuasar();
    const $route = useRoute();
    const $router = useRouter();
    const { isHost } = AppGeneralVariables();
    const accountStore = useAccountStore();
    const centralStore = useCentralStore();
    const viewStore = useViewStore();
    const user = computed(() => accountStore.user);
    // show login popup with a debounce
    const { subject: debouncedLogin$ } = rxInitSubjectCallback();
    useObservable(debouncedLogin$.pipe(debounceTime(500), tap(() => {
        viewStore.requireLogin({
            required: true,
            forced: true,
        });
    })));
    /**
     * Redirect if user does not have the appropriate permissions for
     * the respective page
     */
    function redirectPrivatePages() {
        var _a;
        const { hostPages, privatePages } = parsePrivatePages();
        // redirect to login page if not logged in and trying to access a restricted page
        if (!user.value && privatePages.indexOf($route.name) > -1) {
            centralStore.setPreLoginPath({
                name: ((_a = $route.name) === null || _a === void 0 ? void 0 : _a.toString()) || 'Search',
                params: $route.params,
                query: $route.query,
            });
            $q.loading.hide();
            void $router.push({ name: 'Search' });
            debouncedLogin$.next('');
        }
        else if (!isHost.value &&
            hostPages.indexOf($route.name) > -1) {
            // for flexi rental pages host pages but user is not a host
            void $router.push({ name: 'Rent Out Spaces Flexibly' });
        }
    }
    watch($route, () => {
        // redirect here for when the app is loaded
        // and user somehow manages to route to a page
        // that requires specific permissions
        redirectPrivatePages();
    });
    const { subject: debouncedRedirect$ } = rxInitSubjectCallback();
    useObservable(debouncedRedirect$.pipe(debounce(() => process.env.MODE === 'ssr' && $q.platform.is.mobile
        ? timer(500)
        : timer(0)), tap(() => {
        redirectPrivatePages();
    })));
    onMounted(() => {
        debouncedRedirect$.next('');
    });
};
export { RedirectPlugin };
