import useMeta from 'quasar/src/composables/use-meta/use-meta.js';
import { computed, ref, watch } from 'vue';
import { ModeSwitcher } from 'src/plugins/meta/modeSwitcher';
import { useMetaStore } from 'src/stores/meta';
import cloneDeep from 'lodash.clonedeep';
/**
 * Initialize quasar meta
 */
const MetaPlugin = () => {
    const metaStore = useMetaStore();
    const { switchMode } = ModeSwitcher();
    switchMode();
    /****
     * Title related
     ****/
    const StoreTitle = computed({
        get: () => metaStore.title,
        set: val => {
            metaStore.replaceTitle(val);
        }
    });
    const title = ref(cloneDeep(StoreTitle.value));
    watch(StoreTitle, (newValue) => {
        title.value = newValue;
    });
    /****
     * Meta related
     ****/
    const StoreMeta = computed({
        get: () => metaStore.meta,
        set: val => {
            metaStore.replaceMeta(val);
        }
    });
    const meta = ref(cloneDeep(StoreMeta.value));
    watch(StoreMeta, (newValue) => {
        meta.value = newValue;
    });
    /****
     * Link related
     ****/
    const StoreLink = computed({
        get: () => metaStore.link,
        set: val => {
            metaStore.replaceLink(val);
        }
    });
    const link = ref(cloneDeep(StoreLink.value));
    watch(StoreLink, (newValue) => {
        link.value = newValue;
    });
    /****
     * Script related
     ****/
    const StoreScript = computed({
        get: () => metaStore.script,
        set: val => {
            metaStore.replaceScript(val);
        }
    });
    const script = ref(cloneDeep(StoreScript.value));
    watch(StoreScript, (newValue) => {
        script.value = newValue;
    });
    // init meta
    useMeta(() => {
        return {
            title: title.value,
            titleTemplate: baseTitle => {
                if (['Listing', 'Property'].indexOf(baseTitle) > -1) {
                    return baseTitle;
                }
                if (baseTitle === 'Home') {
                    return title.value;
                }
                return `${baseTitle} | Flexible Rentals`;
            },
            meta: meta.value,
            link: link.value,
            script: script.value
        };
    });
};
export { MetaPlugin };
