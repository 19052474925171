// Search store
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useSearchStore = defineStore('searches', () => {
    /***
     * Map related zoom, center or filters
     ****/
    // Default map center
    const center = ref(null);
    /**
     * Set default map center of the country which the user is at
     * @param jsonData center
     */
    function setMapCenter(jsonData) {
        center.value = jsonData;
    }
    const zoom = ref(null);
    /**
     * Set default map zoom of the country which the user is at
     * @param zoomData zoom val (number)
     */
    function setMapZoom(zoomData) {
        zoom.value = zoomData;
    }
    const map = ref(null);
    const mapMoved = ref(false);
    const savedGoogleInput = ref('');
    const savedGoogleInputMap = ref('');
    /**
     * Update map value
     * @param jsonData map data (zoom and center)
     *  to be panned to when value is shown
     */
    function updateMap(jsonData) {
        map.value = Object.assign(Object.assign({}, map.value), jsonData);
    }
    /**
     * Reset google map value
     */
    function resetMap() {
        map.value = null;
        mapMoved.value = false;
        savedGoogleInput.value = '';
        savedGoogleInputMap.value = '';
    }
    /**
     * Google input value for place input
     * @param data google input string place name
     */
    function setGoogleInput(data) {
        savedGoogleInput.value = data;
    }
    /**
     * Google input value for place input inside
     * the map
     * @param data google input string place name
     */
    function setGoogleInputMap(data) {
        savedGoogleInputMap.value = data;
    }
    /**
     * If map is moved
     * @param boo boolean
     */
    function setMapMovement(boo) {
        mapMoved.value = boo;
    }
    /***
     * Other filters
     ****/
    const clearAll = ref(false);
    /**
     * indicate to the filter component to clear all filters
     * @param boo boolean
     */
    function clearAllFilters(boo) {
        clearAll.value = boo;
    }
    const filters = ref({});
    const ordering = ref('');
    const page = ref('1');
    /**
     * Change filter value in bulk
     * @param filterData filter data
     */
    function bulkChangeFilters(filterData) {
        filters.value = Object.assign(Object.assign({}, filters.value), filterData);
    }
    /**
     * Clear all filters, ordering and page value
     */
    function clearFilters() {
        const filtersCopy = Object.assign({}, filters.value);
        filters.value = Object.assign(Object.assign({}, ('space_charge_type' in filtersCopy
            ? {
                space_charge_type: filtersCopy.space_charge_type
            }
            : 'listing_type' in filtersCopy
                ? {
                    listing_type: filtersCopy.listing_type
                }
                : {})), ("search_type" in filtersCopy
            ? {
                search_type: filtersCopy.search_type
            }
            : {}));
        page.value = '1';
        ordering.value = '';
    }
    /**
     * Completely replace the filters
     * @param filterData filter data
     */
    function replaceFilters(filterData) {
        filters.value = filterData;
    }
    /**
     * Remove a filter by key
     * @param filter filter key
     */
    function removeFilters(filterKey) {
        const filtersCopy = Object.assign({}, filters.value);
        for (let i = 0; i < filterKey.type.length; i++) {
            delete filtersCopy[filterKey.type[i]];
        }
        filters.value = filtersCopy;
    }
    /**
     * Set search order by value
     * @param data ordering value
     */
    function setOrdering(data) {
        ordering.value = data;
    }
    /**
     * Set search page value
     * @param data page value
     */
    function setPage(data) {
        page.value = data;
    }
    /******
     * Search page UI related
     ******/
    // map on the side
    const sideMap = ref(true);
    function toggleSideMap(boo) {
        sideMap.value = boo;
    }
    // map listing
    const mapListingScrollPos = ref(0);
    /**
     * Set position of where the map
     * listing is scrolled to
     * @param pos position
     */
    function setMapListingPos(pos) {
        mapListingScrollPos.value = pos;
    }
    // search page view mode
    const searchPageView = ref('listing');
    function setSearchView(data) {
        searchPageView.value = data;
    }
    const searchScrollPos = ref(0);
    /**
     * Save scroll position of the search page
     * to return to when user visits the search page
     * again from other pages
     * @param pos number
     */
    function setSearchScrollPos(pos) {
        searchScrollPos.value = pos;
    }
    const showFilter = ref(false);
    /**
     * Show filter popup
     * @param boo boolean
     */
    function toggleFilter(boo) {
        showFilter.value = boo;
    }
    /******
     * Search results related
     ******/
    const resultCount = ref(0);
    /**
     * Number of total results
     * @param data number
     */
    function setResultCount(data) {
        resultCount.value = data;
    }
    // search loading
    const searchLoading = ref(false);
    /**
     * search page loading
     * @param boo boolean
     */
    function setSearchLoading(boo) {
        searchLoading.value = boo;
    }
    const searchResults = ref([]);
    /**
     * Save search results to store
     * @param data search results
     */
    function setSearchResults(data) {
        searchResults.value = data;
    }
    const selectedListingId = ref(null);
    /**
     * when user clicks on a map marker, set this value to be queried
     * @param listingId listing id list delimetered by comma
     */
    function setMapListingSelection(listingId) {
        selectedListingId.value = listingId;
    }
    return {
        center,
        setMapCenter,
        zoom,
        setMapZoom,
        map,
        updateMap,
        resetMap,
        setMapMovement,
        savedGoogleInput,
        savedGoogleInputMap,
        setGoogleInput,
        setGoogleInputMap,
        clearAll,
        clearAllFilters,
        filters,
        ordering,
        page,
        bulkChangeFilters,
        clearFilters,
        replaceFilters,
        removeFilters,
        setOrdering,
        setPage,
        sideMap,
        toggleSideMap,
        mapListingScrollPos,
        setMapListingPos,
        searchPageView,
        setSearchView,
        searchScrollPos,
        setSearchScrollPos,
        showFilter,
        toggleFilter,
        resultCount,
        setResultCount,
        searchLoading,
        setSearchLoading,
        searchResults,
        setSearchResults,
        selectedListingId,
        setMapListingSelection
    };
});
