// Store for capacitor related activites
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useAppModeStore = defineStore('app', () => {
    /*****
     * toggle tabs
     * ****/
    const appTab = ref('searchTab');
    /**
     * Toggle app tab
     * @param tab tab to toggle
     */
    function toggleAppTab(tab) {
        appTab.value = tab;
    }
    const savedTab = ref(null);
    /**
     * Save tab to navigate to, before user logs in
     * @param tab tab to navigate to after logging in
     */
    function toggleSavedTab(tab) {
        savedTab.value = tab;
    }
    const tabRoute = ref({
        hostTab: null,
        accountTab: null,
    });
    /**
     * Save the actual route of host tab and account tab
     * so that when we navigate to the tab, we can go back to the actual
     * place where they have been to
     * @param tab tab
     * @param route actual route
     */
    function saveTabRoute(tab, route) {
        tabRoute.value = Object.assign(Object.assign({}, tabRoute.value), { [tab]: route });
    }
    /*****
     * save scroll pos of each tab
     * ****/
    const appScrollPosList = ref({
        searchTab: 0,
        chatTab: 0,
        notificationTab: 0,
        bookingTab: 0,
        hostTab: 0,
        accountTab: 0,
    });
    /**
     * Save scroll pos
     * @param scrollPosSet appScrollPosList set
     */
    function toggleScrollPos(scrollPosSet) {
        appScrollPosList.value = scrollPosSet;
    }
    /*****
     * Reset scroll in main page
     * ****/
    const resetPageScroll = ref(false);
    /**
     * called from children to reset parent scroll position
     * @param scrollReset boolean
     */
    function toggleResetPageScroll(scrollReset) {
        resetPageScroll.value = scrollReset;
    }
    const scrollToTop = ref(null);
    /**
     * Scroll a particular tab to top
     * @param tab tab
     */
    function toggleScrollTop(tab) {
        scrollToTop.value = tab;
    }
    /*****
     * User booking confirmation dialog
     * ****/
    const bookingDialog = ref(false);
    /**
     * When guests pay for custom booking
     * @param boo boolean
     */
    function toggleBookingDialog(boo) {
        bookingDialog.value = boo;
    }
    /*****
     * Host custom booking dialog
     * ****/
    const customBookingDialog = ref(null);
    /**
     * When guests pay for custom booking
     * @param boo boolean
     */
    function toggleCustomBookingDialog(data) {
        customBookingDialog.value = data;
    }
    /*****
     * view host profile
     * ****/
    const hostQuery = ref(null);
    /**
     * When user views host profile. Save id
     * of hosts to query
     * @param id id of host
     */
    function setHostQuery(id) {
        hostQuery.value = id;
    }
    /*****
     * set if keyboard is shown
     * ****/
    const keyboardPreShowHt = ref(0);
    /**
     * Set if keyboard is shown
     */
    function toggleKeyboardPreShowHt(keyboardHt) {
        keyboardPreShowHt.value = keyboardHt;
    }
    /*****
     * set height of keyboard when its fully shown
     * ****/
    const keyboardHtVal = ref(0);
    /**
     * Save to offset keyboard height for components
     * @param keyboardHt keyboard height
     */
    function toggleKeyboardHt(keyboardHt) {
        keyboardHtVal.value = keyboardHt;
    }
    /*****
     * Show the top of the listing
     * (transparent banner with close btn)
     * ****/
    const showListingBanner = ref(true);
    function toggleShowListingBanner(boo) {
        showListingBanner.value = boo;
    }
    /*****
     * when user views property
     * ****/
    const exPropertyQuery = ref([]);
    const searchProperty = ref(null);
    const showAppProperty = ref(false);
    /**
     * When user routes to property listing
     * @param param0 {
        id = property id,
        clearAll = if we clear all data
      }
     * @returns void
     */
    function toggleSearchProperty({ id = null, clearAll = false }) {
        if (!id) {
            // when user closes property page
            hostQuery.value = null;
            if ((exPropertyQuery.value.length > 0) && !clearAll) {
                // show previous property page when user close current one
                const exPropertyQueryCopy = [...exPropertyQuery.value];
                const poppedElement = exPropertyQueryCopy.pop();
                searchProperty.value = poppedElement || null;
                exPropertyQuery.value = exPropertyQueryCopy;
                return;
            }
            // close all property popups
            searchProperty.value = null;
            exPropertyQuery.value = [];
            return;
        }
        // when user enters another property listing
        // through the current property listing (i.e related listings)
        if (searchProperty.value) {
            const exPropertyQueryCopy = [...exPropertyQuery.value];
            exPropertyQueryCopy.push(searchProperty.value);
            exPropertyQuery.value = exPropertyQueryCopy;
        }
        searchProperty.value = id;
    }
    /**
     * property listing page popup
     * @param boo boolean
     */
    function toggleShowAppProperty(boo) {
        showAppProperty.value = boo;
    }
    /*****
     * when user views flex listing
     * ****/
    const exSearchVenue = ref([]);
    const searchVenue = ref(null);
    const showAppListing = ref(false);
    /**
     * When user routes to flex listing
     * @param param0 {
        id = flex listing id,
        clearAll = if we clear all data. i.e close
          all venue dialogs
      }
     * @returns void
     */
    function toggleSearchVenue({ id = null, clearAll = false }) {
        if (!id) {
            // when user closes flex listing page
            hostQuery.value = null;
            if ((exSearchVenue.value.length > 0) && !clearAll) {
                // show previous flex listing page when user close current one
                const exSearchVenueCopy = [...exSearchVenue.value];
                const poppedElement = exSearchVenueCopy.pop();
                searchVenue.value = poppedElement || null;
                exSearchVenue.value = exSearchVenueCopy;
                return;
            }
            // close all flex listing popups
            searchVenue.value = null;
            exSearchVenue.value = [];
            return;
        }
        // when user enters another flex listing
        // through the current flex listing (i.e related listings)
        if (searchVenue.value) {
            const exSearchVenueCopy = [...exSearchVenue.value];
            exSearchVenueCopy.push(searchVenue.value);
            exSearchVenue.value = exSearchVenueCopy;
        }
        searchVenue.value = id;
    }
    /**
     * flex listing page popup
     * @param boo boolean
     */
    function toggleShowAppListing(boo) {
        showAppListing.value = boo;
    }
    const showBookSuccess = ref(null);
    /**
     * show booking success popup
     * @param str orderId
     */
    function toggleShowBookSuccess(orderId) {
        showBookSuccess.value = orderId;
    }
    /*****
     * when user wants to review order
     * ****/
    const reviewOrderId = ref(null);
    /**
     * When user clicks on "review" btn
     * @param orderId OrderID for reviewing
     */
    function toggleReviewOrderId(orderId) {
        reviewOrderId.value = orderId;
    }
    /*****
     * when user wants to purchase credit
     * ****/
    const showCreditPurchase = ref(false);
    /**
     * credit purchase popup
     * @param boo boolean
     */
    function toggleShowCreditPurchase(boo) {
        showCreditPurchase.value = boo;
    }
    /*****
     * App footer
     * ****/
    const showFooter = ref(true);
    /**
     * show footer on app
     * @param boo boolean
     */
    function toggleShowFooter(boo) {
        showFooter.value = boo;
    }
    /*****
     * Chat
     * ****/
    const showChatDialog = ref(false);
    function toggleShowChatDialog(boo) {
        showChatDialog.value = boo;
    }
    return {
        appTab,
        toggleAppTab,
        savedTab,
        toggleSavedTab,
        tabRoute,
        saveTabRoute,
        appScrollPosList,
        toggleScrollPos,
        resetPageScroll,
        toggleResetPageScroll,
        scrollToTop,
        toggleScrollTop,
        bookingDialog,
        toggleBookingDialog,
        customBookingDialog,
        toggleCustomBookingDialog,
        hostQuery,
        setHostQuery,
        keyboardPreShowHt,
        toggleKeyboardPreShowHt,
        keyboardHtVal,
        toggleKeyboardHt,
        showListingBanner,
        toggleShowListingBanner,
        exPropertyQuery,
        searchProperty,
        showAppProperty,
        toggleSearchProperty,
        toggleShowAppProperty,
        exSearchVenue,
        searchVenue,
        showAppListing,
        toggleSearchVenue,
        toggleShowAppListing,
        showBookSuccess,
        toggleShowBookSuccess,
        reviewOrderId,
        toggleReviewOrderId,
        showCreditPurchase,
        toggleShowCreditPurchase,
        showFooter,
        toggleShowFooter,
        showChatDialog,
        toggleShowChatDialog
    };
});
