import { useRouter } from 'vue-router';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { computed } from 'vue';
import { useCentralStore } from 'src/stores/central';
import { AppGeneralVariables } from 'src/plugins/general/variables';
/**
 * Standardize logic before refreshing page
 * @returns refresh page function
 */
const RefreshPagePlugin = () => {
    const router = useRouter();
    const $q = useQuasar();
    const centralStore = useCentralStore();
    const { styleType } = AppGeneralVariables();
    const hasWaiting = computed(() => centralStore.hasWaiting);
    /**
     * Refresh page
     * @param skipWaitingCheck skip cache update
     * @param forceRefresh force refresh now
     */
    function refreshPage(skipWaitingCheck = false, forceRefresh = false) {
        const platformIs = $q.platform.is;
        if (hasWaiting.value && !skipWaitingCheck) {
            /*
            $q.loading.show({
              message: 'Getting updated data',
              spinnerSize: 250,
              spinnerColor: 'accent'
            })
            */
            centralStore.toggleVapidKeyInstalled(true);
        }
        else if (styleType.value !== 'app') {
            router.go(0);
        }
        else if (forceRefresh) {
            centralStore.toggleSearchPageVisited(null);
            if (platformIs.capacitor && platformIs.ios) {
                $q.loading.hide();
                document.location.reload();
            }
            else {
                router.go(0);
            }
        }
        else {
            centralStore.setRequeryInit();
        }
    }
    return {
        refreshPage
    };
};
export { RefreshPagePlugin };
