import { ref as _ref, onBeforeUnmount } from 'vue';
import { Subject } from 'rxjs';
/*
Example Usage:

setup() {
  const { subject: plus$, callback: plus } = rxInitSubjectCallback()
  const count = useObservable(
    plus$.pipe(
      map(() => 1),
      startWith(0),
      scan((total, change) => total + change)
    )
  )
  return {
    count,
    plus
  }
}
*/
/**
 * Subscribes and ensures unsubscription when component is unmounted
 * @param observable
 * @param next
 * @param error
 * @param complete
 * @returns
 */
function subscribeTo(observable, next, error, complete) {
    const subscription = observable.subscribe(next, error, complete);
    onBeforeUnmount(() => {
        subscription.unsubscribe();
    });
    return subscription;
}
/**
 * add custom subscription functions
 * @param observable
 * @param next
 * @param error
 * @param complete
 * @returns
 */
function useSubscription(observable, next, error, complete) {
    return subscribeTo(observable, next, error, complete);
}
/**
 * Add the observable to the Subject and alters the Ref
 * @param observable Observable
 * @param defaultValue default value if null
 * @returns Ref
 */
function useObservable(observable, defaultValue) {
    const handler = _ref(defaultValue);
    subscribeTo(observable, value => {
        handler.value = value;
    }, error => {
        throw error;
    });
    return handler;
}
/**
 * Register subject and callback event
 * @returns subject with callback
 */
function rxInitSubjectCallback() {
    const subject = new Subject();
    return {
        subject,
        callback: (event) => {
            subject.next(event);
        }
    };
}
export { useObservable, useSubscription, rxInitSubjectCallback };
