export default {
  "Flexible Rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灵活出租"])},
  "Flex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灵活"])},
  "Full Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完整空间"])},
  "Coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享空间"])},
  "Responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通常在", _interpolate(_named("hours")), "小时内回复"])},
  "Typically responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通常在", _interpolate(_named("hours")), "小时内回复"])},
  "Instant Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即时确认"])},
  "Payment Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付详情"])},
  "Hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按小时"])},
  "Day-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按天"])},
  "Hourly pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按小时"])},
  "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按天"])},
  "Flex Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灵活出租"])},
  "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])},
  "Month Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])},
  "Month pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])},
  "pax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
  "ENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已结束"])},
  "UPCOMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将开始"])},
  "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "canceled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])},
  "canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员取消"])},
  "canceled_by_guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户取消"])},
  "canceled_by_guest_wif_things_considered_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户取消"])},
  "canceled_by_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房东取消"])},
  "charge_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付失败"])},
  "charge_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付处理中"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待批准"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])},
  "hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小时"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
  "mth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])}
}