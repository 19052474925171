import enMsgs from './en-us';
import idMsgs from './id';
import zhMsgs from './zh';
const messages = {
    'zh': zhMsgs,
    'id': idMsgs,
    'en-US': enMsgs,
    'en': enMsgs,
};
export { messages };
