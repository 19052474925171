import date from 'quasar/src/utils/date/date.js';
const { getDateDiff, addToDate } = date;
function tteCalculator(user) {
    if (!user || (user && !user.access_token_expires)) {
        return 0;
    }
    // try to refresh user 3 days before expiry
    const userExpiryCountdown = date.getDateDiff(date.addToDate(new Date(user.access_token_expires), {
        days: -10
    }), new Date(), 'seconds');
    // max 32 bit integer
    const maxCountdown = 2147483647;
    // countdown
    const expiryCountdown = userExpiryCountdown * 1000;
    return expiryCountdown > maxCountdown
        ? maxCountdown
        : expiryCountdown;
}
export { tteCalculator };
