const successParams = {
    timeout: 5000,
    type: 'positive',
    color: 'positive',
    textColor: 'white',
    icon: 'done_all',
    position: 'top',
    closeBtn: 'Close'
};
const errorParams = {
    classes: 'data-cy-error',
    closeBtn: 'Close',
    color: 'red-10',
    icon: 'cancel',
    position: 'top',
    textColor: 'white',
    timeout: 5000,
    type: 'negative',
};
export { errorParams, successParams };
