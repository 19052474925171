// Meta store
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useMetaStore = defineStore('meta', () => {
    const title = ref('GetSpaces');
    function replaceTitle(payload) {
        title.value = payload;
    }
    const meta = ref({
        viewport: {
            name: 'viewport',
            id: 'viewport',
            content: 'initial-scale=1,width=device-width,height=device-height,viewport-fit=cover'
        },
        robots: {
            name: 'robots',
            content: 'all'
        },
        description: {
            name: 'description'
        },
        'og:site_name': {
            property: 'og:site_name'
        },
        'og:url': {
            property: 'og:url'
        },
        'og:title': {
            property: 'og:title'
        },
        'og:description': {
            property: 'og:description'
        },
        'og:image': {
            property: 'og:image'
        },
        'twitter:site': {
            property: 'twitter:site'
        },
        'twitter:creator': {
            property: 'twitter:creator'
        }
    });
    function replaceMeta(payload) {
        meta.value = payload;
    }
    const link = ref({
        canonical: {
            rel: 'canonical'
        }
    });
    function replaceLink(payload) {
        link.value = payload;
    }
    const script = ref({});
    function replaceScript(payload) {
        script.value = payload;
    }
    return {
        title,
        replaceTitle,
        meta,
        replaceMeta,
        link,
        replaceLink,
        script,
        replaceScript
    };
});
