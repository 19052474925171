import { generateSrcString } from 'src/plugins/images/generateSrcString';
import { dtWithLocaleFormat } from 'src/plugins/date/dtWithLocale';
import { tagElementRemoval, htmlRemoval, parseCamelCase, stringParser } from 'src/plugins/string/string';
import date from 'quasar/src/utils/date/date.js';
const { addToDate } = date;
// library containing all structured data types
// that this app uses
class MetaBuilderLibrary {
    constructor({ currentMeta, currentLink, currentScript }, description = '') {
        this.parsedDesc = {
            tagDataRemoved: tagElementRemoval(description),
            htmlRemoved: htmlRemoval(description)
        };
        this.currentMeta = currentMeta;
        this.currentLink = currentLink;
        this.currentScript = currentScript;
    }
    /******
     * Structured data related
     ******/
    /**
     * Structured data that describes a product
     * @param data product related data
     */
    setProductStructuredData(data) {
        const { formatDateWithSysLocale } = dtWithLocaleFormat();
        this.currentScript[`${data.name}-product`] = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(Object.assign(Object.assign({ '@context': 'https://schema.org/', '@type': 'Product', 'name': data.name, 'image': data.images.map((image) => image.url), 'description': data.description, 'sku': data.pageId, 'brand': {
                    '@type': 'Brand',
                    'name': 'GetSpaces'
                }, 'offers': {
                    '@type': 'Offer',
                    'url': data.url,
                    'priceCurrency': 'SGD',
                    'price': Number(data.price).toFixed(2),
                    'priceValidUntil': formatDateWithSysLocale(date.addToDate(new Date(), { days: 90 }), 'YYYY-MM-DD', true),
                    'itemCondition': 'https://schema.org/NewCondition',
                    'availability': 'https://schema.org/InStock'
                } }, (data.reviewCount > 0 ? {
                aggregateRating: {
                    '@type': 'AggregateRating',
                    'ratingValue': (Number(data.rating) || 5).toFixed(0),
                    'reviewCount': (Number(data.reviewCount) || 1).toFixed(0)
                }
            } : {})), (data.reviews.length > 0 ? {
                review: {
                    '@type': 'Review',
                    'reviewRating': {
                        '@type': 'Rating',
                        'ratingValue': (Number(data.reviews[0].rating) || 5).toFixed(0),
                        'bestRating': '5'
                    },
                    'author': {
                        '@type': 'Person',
                        'name': data.reviews[0].author
                    },
                    'reviewBody': data.reviews[0].review_desc
                }
            } : {})))
        };
    }
    setReviewStructuredData(review) {
        const reviewSpaceParsed = stringParser(review.space_pk.toString(), review.space_name);
        this.currentScript[`${reviewSpaceParsed}-${review.created_at}`] = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Review',
                'reviewRating': {
                    '@type': 'Rating',
                    'bestRating': '5',
                    'ratingValue': (Number(review.rating) || 5).toFixed(0),
                },
                'author': {
                    '@type': 'Person',
                    'name': review.author
                },
                'itemReviewed': {
                    '@type': 'Product',
                    'name': review.space_name,
                    'url': `${process.env.domain}/listing/${reviewSpaceParsed}`,
                    'review': {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': (Number(review.rating) || 5).toFixed(0),
                            'bestRating': '5'
                        },
                        'author': {
                            '@type': 'Person',
                            'name': review.author
                        }
                    },
                },
                'datePublished': review.created_at,
                'description': review.review_desc
            })
        };
    }
    /**
     * Data thay can be used for image search
     * @param data image data
     */
    setImageStructuredData(data) {
        for (let i = 0; i < data.images.length; i++) {
            this.currentScript[`${data.name}-images-${i}`] = {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'ImageObject',
                    'contentUrl': data.images[i].url,
                    'creditText': data.name,
                    'creator': {
                        '@type': 'Person',
                        'name': 'GetSpaces'
                    },
                    'license': 'https://getspaces.com/policies?tabs=Platform+Terms+of+Use',
                    'acquireLicensePage': 'https://getspaces.com/contactus',
                    'copyrightNotice': 'GetSpaces'
                })
            };
        }
    }
    /**
     * Data that can be used for video search on google
     * @param data video related data
     */
    setVideoStructuredData(data) {
        for (let i = 0; i < data.videos.length; i++) {
            const videoData = data.videos[i];
            if (!videoData.url)
                continue;
            const videoId = videoData.url.split('embed/')[1];
            const videoUrlSplit = videoData.url.split('/');
            const thumbnailUrl = `https://i.ytimg.com/vi/${videoUrlSplit[videoUrlSplit.length - 1]}/hqdefault`;
            this.currentScript[`${data.name}-videos-${i}`] = {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'VideoObject',
                    'name': `${parseCamelCase(videoData.content_type)} video for ${data.name}`,
                    'description': videoData.caption || `${parseCamelCase(videoData.content_type)} video for ${data.name}`,
                    'thumbnailUrl': [thumbnailUrl],
                    'uploadDate': new Date(data.created_at).toISOString(),
                    'contentUrl': `https://www.youtube.com/watch?v=${videoId}`,
                    'embedUrl': videoData.url
                })
            };
        }
    }
    /**
     * FAQ type of data to be displayed along side with
     * the page on google
     * @param faqs FAQ data
     */
    setFaqStructuredData(faqs) {
        const faqList = [];
        for (let i = 0; i < faqs.length; i++) {
            faqList.push({
                '@type': 'Question',
                'name': faqs[i].title,
                'acceptedAnswer': {
                    '@type': 'Answer',
                    'text': faqs[i].content
                }
            });
        }
        this.currentScript.faqsData = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                'mainEntity': faqList
            })
        };
    }
    /**
     * Set software type data for app
     * @param isIos is the app for ios
     */
    setSoftwareStructuredData(isIos) {
        this.currentScript.softWareInfo = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': ['WebApplication', 'MobileApplication'],
                'name': 'GetSpaces',
                'operatingSystem': 'ANDROID, IOS, WINDOWS, OS X, LINUX',
                'applicationCategory': 'BusinessApplication',
                'applicationSubCategory': 'Rental, Short term flexible space, chat, calendar',
                'aggregateRating': {
                    '@type': 'AggregateRating',
                    'ratingValue': '4.96',
                    'ratingCount': '5058'
                },
                'offers': {
                    '@type': 'Offer',
                    'price': '0'
                },
                'downloadUrl': isIos
                    ? 'https://apps.apple.com/us/app/id1516262495'
                    : 'https://play.google.com/store/apps/details?id=org.getspaces.app',
            })
        };
    }
    /**
     * Set breadcrumbs for the page
     * @param breadcrumbs breadcrumb data
     */
    setBreadcrumbStructuredData(breadcrumbs) {
        const breadCrumbList = [];
        for (let i = 0; i < breadcrumbs.length; i++) {
            const breadcrumbData = breadcrumbs[i];
            breadCrumbList.push({
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                'itemListElement': [{
                        '@type': 'ListItem',
                        'position': breadcrumbData.position,
                        'name': breadcrumbData.name,
                        'item': breadcrumbData.url
                    }]
            });
        }
        this.currentScript.breadcrumbsData = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(breadCrumbList)
        };
    }
    /**
     * Standard structured data for the GetSpaces company
     */
    setCompanyStructuredData(images) {
        this.currentScript.organization = {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                'name': 'GetSpaces',
                image: images.map((image) => image.url),
                'geo': {
                    '@type': 'GeoCoordinates',
                    'latitude': 1.3060506,
                    'longitude': 103.854022
                },
                url: 'https://getspaces.com',
                logo: 'https://dminqct125wnz.cloudfront.net/spaces/appIcons/icons/ms-icon-144x144.png'
            })
        };
    }
    /******
     * Set Meta related
     ******/
    setMeta(data) {
        this.currentMeta = Object.assign(Object.assign({}, this.currentMeta), { 'og:title': Object.assign(Object.assign({}, this.currentMeta['og:title']), { content: `${data.name} | GetSpaces` }), 'og:description': Object.assign(Object.assign({}, this.currentMeta['og:description']), { content: this.parsedDesc.htmlRemoved }), 'og:url': Object.assign(Object.assign({}, this.currentMeta['og:url']), { content: data.url }), 'og:image': Object.assign(Object.assign({}, this.currentMeta['og:image']), { content: data.images.length > 0
                    ? data.images[0].url
                    : '' }), description: Object.assign(Object.assign({}, this.currentMeta.description), { content: this.parsedDesc.htmlRemoved }), robots: Object.assign(Object.assign({}, this.currentMeta.robots), { content: data.active && (process.env.prodType === 'prod')
                    ? 'all'
                    : 'noindex' }) });
    }
    /******
     * Preload related
     ******/
    imageParser(pageId, 
    // image specific
    { index, image, originalSize, sizeType, imageSizes }) {
        return Object.assign({ rel: 'preload', as: 'image', href: 'https://dminqct125wnz.cloudfront.net/common/small.gif', imagesrcset: generateSrcString({
                url: image,
                originalSize,
                sizeType
            }).webp, imagesizes: imageSizes, type: 'image/webp', id: `${pageId}-preload-${index}` }, (index === 0
            ? {
                fetchpriority: 'high'
            } : {}));
    }
    setLink(data) {
        const preloadImageData = {};
        for (let i = 0; i < Math.min(data.images.length, data.preloadCount || 1); i++) {
            preloadImageData[`${data.pageId}-preload-${i}`] = this.imageParser(data.pageId, Object.assign(Object.assign({}, data.imageMeta), { index: i, image: data.images[i].format
                    ? `${data.images[i].url}.${data.images[i].format}`
                    : data.images[i].url }));
        }
        this.currentLink = Object.assign(Object.assign(Object.assign({}, this.currentLink), { canonical: Object.assign(Object.assign({}, this.currentLink.canonical), { href: data.url }) }), preloadImageData);
    }
    /*****
     * Get parsed data
     *****/
    getDesc() {
        return this.parsedDesc;
    }
    getMeta() {
        return this.currentMeta;
    }
    getLink() {
        return this.currentLink;
    }
    getScript() {
        return this.currentScript;
    }
}
export { MetaBuilderLibrary };
