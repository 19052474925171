// Store being used for the central component
// where misc stuffs are put here
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
export const useCentralStore = defineStore('central', () => {
    /*****
     * When stuffs are initialized
     ******/
    const initialized = ref(false);
    /**
     * When the initialized data is received from
     * backend for the first time
     * @param boo boolean
     */
    function toggleInitialized(boo) {
        initialized.value = boo;
    }
    const dbInitialized = ref(false);
    /**
     * When indexedDb has been initialized
     * @param boo boolean
     */
    function toggleDbInitialized(boo) {
        dbInitialized.value = boo;
    }
    const piniaInitialized = ref(false);
    const localStoreInitialized = ref(false);
    /**
     * When the local part of the pinia store has been initialized
     * @param boo boolean
     */
    function toggleLocalStoreInitialized(boo) {
        localStoreInitialized.value = boo;
    }
    const vapidKeyInstalled = ref(false);
    /**
     * When vapidKey has been installed (web push noitifcation)
     * @param boo boolean
     */
    function toggleVapidKeyInstalled(boo) {
        vapidKeyInstalled.value = boo;
    }
    const workerRegistered = ref(false);
    /**
     * When the service worker has been registered
     * @param boo boolean
     */
    function toggleWorkerRegistered(boo) {
        workerRegistered.value = boo;
    }
    /*****
     * App base
     ******/
    const appMounted = ref(false);
    /**
     * If app is mounted
     * @param bool boolean
     */
    function toggleMounted(bool) {
        appMounted.value = bool;
    }
    const appVer = ref('');
    /**
     * set app version of the app
     * @param ver string
     */
    function setAppVer(ver) {
        appVer.value = ver;
    }
    const locale = ref('en-US');
    /**
     * Set locale of the app
     * @param localeVal locale
     */
    function setLocale(localeVal) {
        locale.value = localeVal;
    }
    const mode = ref(process.env.appMode);
    /**
     * Default app type on create
     * @param modeVal 'app' | 'desktop' | 'mobile'
     */
    function setMode(modeVal) {
        mode.value = modeVal;
    }
    const online = ref(true);
    /**
     * Detect if user is connected to internet
     * @param bool boolean
     */
    function toggleOnline(bool) {
        online.value = bool;
    }
    const backendOnline = ref(true);
    /**
     * Detect if backend is down
     * @param bool boolean
     */
    function toggleBackendOnline(bool) {
        backendOnline.value = bool;
    }
    const platformInfo = ref({});
    /**
     * Set platform info that can be accessed globally
     * @param data platform info
     */
    function setPlatformInfo(data) {
        platformInfo.value = data;
    }
    /*****
     * App related data from backend
     ******/
    const cashback = ref(0);
    /**
     * @param num general cashback percentage
     */
    function toggleCashBack(num) {
        cashback.value = num;
    }
    const maxCashback = ref(0);
    /**
     * Maximum cashback amount for each review
     * @param num max cashback value
     */
    function toggleMaxCashBack(num) {
        maxCashback.value = num;
    }
    const beforeRefresh = ref(false);
    /**
     * Pause refresh until all old caches are cleared
     * @param bool boolean
     */
    function toggleBeforeRefresh(bool) {
        beforeRefresh.value = bool;
    }
    const hasWaiting = ref(false);
    /**
     * Has new app data, pending user refresh
     * @param bool boolean
     */
    function toggleHasWaiting(bool) {
        hasWaiting.value = bool;
    }
    const country = ref('');
    /**
     * Set country which the user is at
     * @param countryVal country id
     */
    function toggleCountry(countryVal) {
        country.value = countryVal;
    }
    const timezone = ref('');
    /**
     * Set timezone which the user is at
     * @param timezoneVal IANA timezone
     */
    function toggleTimezone(timezoneVal) {
        timezone.value = timezoneVal;
    }
    /*****
     * Route
     ******/
    const currentPath = ref(null);
    /**
     * Current route data
     * @param routeData route related data
     */
    function setCurrentPath(routeData) {
        currentPath.value = routeData;
    }
    const savedPath = ref(null);
    /**
     * Previous route data
     * @param routeData route related data
     */
    function setSavedPath(routeData) {
        savedPath.value = routeData;
    }
    const preloginPath = ref(null);
    /**
     * Route to redirect the user to after login
     * @param routeData route related data
     */
    function setPreLoginPath(routeData) {
        preloginPath.value = routeData;
    }
    const searchPageVisited = ref(null);
    /**
     * If search page is visited. Somehow there will be some strange offset
     * in older iPhones after visiting the search page. So need to create the offset
     * @param data search page type
     */
    function toggleSearchPageVisited(data) {
        searchPageVisited.value = data;
    }
    const hostRegistration = ref(false);
    /**
     * Go straight to host registration page
     * @param bool boolean
     */
    function toggleHostRegistration(bool) {
        hostRegistration.value = bool;
    }
    /*****
     * Misc
     ******/
    const preloadImageList = ref([]);
    /**
     * List of images to preload
     * @param data image url
     */
    function setPreloadList(data) {
        preloadImageList.value = [
            ...preloadImageList.value,
            data
        ];
    }
    const rewardPurchaseDeposited = ref(null);
    /**
     * When payment has been made to purchase credits,
     * backend will update firebase and depending on the
     * data returned from firebase, if the purchase is fully paid,
     * we display the dialog that shows that the reward has been deposited
     * to the user's account
     * @param data payment made data
     */
    function setRewardDeposited(data) {
        rewardPurchaseDeposited.value = data;
    }
    const leadsPurchaseCompleted = ref(null);
    /**
     * When payment has been made to purchase leads,
     * backend will update firebase and depending on the
     * data returned from firebase, if the purchase is fully paid,
     * we display the dialog that shows that the lead purchase has succeeded
     * @param data payment made data
     */
    function setLeadsPurchaseComplete(data) {
        leadsPurchaseCompleted.value = data;
    }
    const requeryingInit = ref(false);
    function setRequeryingInit(boo) {
        requeryingInit.value = boo;
    }
    const requeryInit = ref({
        ver: '',
        loading: false
    });
    /**
     * Call the requery api to query for user data
     * when a new string is set
     */
    function setRequeryInit(loading = false) {
        requeryInit.value = {
            ver: uuidv4(),
            loading
        };
    }
    const retryAxios = ref([]);
    /**
     * Push to the list of backend calls to retry after token refresh
     * @param data data for axios call
     */
    function pushToRetryQueue(data) {
        retryAxios.value = [
            ...retryAxios.value,
            data
        ];
    }
    /**
     * Remove from queue 1 by one after trying to refresh token
     * @param id id of queue
     */
    function removeFromQueue(idList) {
        const retryAxiosCopy = [...retryAxios.value];
        idList.forEach((id) => {
            const idIndex = retryAxiosCopy.findIndex((item) => item.id === id);
            if (idIndex > -1) {
                retryAxiosCopy.splice(idIndex, 1);
            }
        });
        retryAxios.value = retryAxiosCopy;
    }
    /**
     * Clear queue entriely as refresh token failed
     */
    function clearQueue() {
        retryAxios.value = [];
    }
    const sectionScroll = ref('');
    /**
     * Scroll to certain sections of the page based on
     * certain confitions (i.e route params etc)
     * @param data section reference to scroll to
     */
    function setSectionScroll(data) {
        sectionScroll.value = data;
    }
    return {
        initialized,
        toggleInitialized,
        dbInitialized,
        toggleDbInitialized,
        piniaInitialized,
        localStoreInitialized,
        toggleLocalStoreInitialized,
        vapidKeyInstalled,
        toggleVapidKeyInstalled,
        workerRegistered,
        toggleWorkerRegistered,
        appMounted,
        toggleMounted,
        appVer,
        setAppVer,
        locale,
        setLocale,
        mode,
        setMode,
        online,
        toggleOnline,
        backendOnline,
        toggleBackendOnline,
        platformInfo,
        setPlatformInfo,
        cashback,
        toggleCashBack,
        maxCashback,
        toggleMaxCashBack,
        beforeRefresh,
        toggleBeforeRefresh,
        hasWaiting,
        toggleHasWaiting,
        country,
        toggleCountry,
        timezone,
        toggleTimezone,
        currentPath,
        setCurrentPath,
        savedPath,
        setSavedPath,
        preloginPath,
        setPreLoginPath,
        searchPageVisited,
        toggleSearchPageVisited,
        hostRegistration,
        toggleHostRegistration,
        preloadImageList,
        setPreloadList,
        rewardPurchaseDeposited,
        setRewardDeposited,
        leadsPurchaseCompleted,
        setLeadsPurchaseComplete,
        requeryingInit,
        setRequeryingInit,
        requeryInit,
        setRequeryInit,
        retryAxios,
        pushToRetryQueue,
        removeFromQueue,
        clearQueue,
        sectionScroll,
        setSectionScroll
    };
});
