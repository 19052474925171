import { computed } from 'vue';
import { useMetaStore } from 'src/stores/meta';
// Plugin for meta update
const SetMetaPlugin = () => {
    const metaStore = useMetaStore();
    const StoreTitle = computed({
        get: () => metaStore.title,
        set: val => {
            metaStore.replaceTitle(val);
        }
    });
    const StoreMeta = computed({
        get: () => metaStore.meta,
        set: val => {
            metaStore.replaceMeta(val);
        }
    });
    const StoreLink = computed({
        get: () => metaStore.link,
        set: val => {
            metaStore.replaceLink(val);
        }
    });
    const StoreScript = computed({
        get: () => metaStore.script,
        set: val => {
            metaStore.replaceScript(val);
        }
    });
    /**
     * Save new title to store
     * @param newTitle Title to be saved
     */
    function setMetaTitle(newTitle) {
        StoreTitle.value = newTitle;
    }
    /**
     * Save new <meta> data to store
     * @param newMeta new meta data
     */
    function setMetaMeta(newMeta) {
        StoreMeta.value = newMeta;
    }
    /**
     * Save <link> data to store
     * @param newLink new link data
     */
    function setMetaLink(newLink) {
        StoreLink.value = newLink;
    }
    /**
     * Save new <script> data to store
     * @param newScript new script data
     */
    function setMetaScript(newScript) {
        StoreScript.value = newScript;
    }
    /**
     * Replace data for <link> tags
     * @param linkData new link data
     */
    function updateMetaLink(linkData) {
        StoreLink.value = Object.assign(Object.assign({}, StoreLink.value), linkData);
    }
    return {
        StoreTitle,
        setMetaTitle,
        StoreMeta,
        setMetaMeta,
        StoreLink,
        setMetaLink,
        updateMetaLink,
        StoreScript,
        setMetaScript,
    };
};
export { SetMetaPlugin };
