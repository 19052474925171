export default {
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulasan"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
  "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemilik"])},
  "Verify Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi Nomor Telepon"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Telepon"])},
  "Country Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Negara"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Lebih Banyak"])},
  "See Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Kurang"])},
  "Show more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Lebih Banyak"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan"])},
  "Show less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan Detail"])},
  "Show Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan Detail"])},
  "Show All": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tampilkan Semua (", _interpolate(_named("count")), ")"])},
  "View Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Detail"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Lebih Lanjut"])},
  "Go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
  "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersihkan"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan"])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin"])},
  "Credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
  "Cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunai"])},
  "Promo code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Promo"])},
  "Browse Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelajahi Ruang"])},
  "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang"])},
  "Message us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beranda"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ke"])},
  "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Aktif"])},
  "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagikan"])},
  "dashboard": {
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat kembali"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor Aplikasi"])},
    "About me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang saya"])},
    "No resources available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sumber daya yang tersedia"])},
    "All values shown below are in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Semua nilai yang ditampilkan di bawah ini dalam ", _interpolate(_named("currency"))])}
  },
  "form": {
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsional"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wajib"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "Select Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai"])},
    "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
    "Start Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Mulai"])},
    "Select Date Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Rentang Tanggal"])},
    "Preferred Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Mulai yang Diinginkan"])},
    "End Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Selesai"])},
    "Select Lease Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal Mulai Sewa"])},
    "Select Lease End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Tanggal Akhir Sewa"])},
    "Cut off date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal batas akhir"])},
    "Add a Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Hari"])},
    "Add a Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Bulan"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "Preferred move in date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pindahan yang diinginkan"])},
    "For recurring bookings, click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk pemesanan berulang, klik"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di sini"])}
  },
  "order": {
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
    "View Listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Penawaran"])},
    "View Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Properti"])},
    "View in Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat di Peta"])},
    "View in Google Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat di Google Map"])},
    "View Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Tanda Terima"])},
    "Add to Google Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan ke Google Calendar"])},
    "Payment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode Pembayaran"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
    "Host Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan Pemilik"])}
  },
  "pages": {
    "Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan"])}
  },
  "size": {
    "Sq Ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaki Persegi"])},
    "Sq M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meter Persegi"])}
  },
  "user": {
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
    "Account pending deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun Menunggu Penghapusan"])},
    "Verify Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi Email"])},
    "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
    "Sign Up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "Joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabung pada"])},
    "Tell us more about yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beritahu kami lebih banyak tentang diri Anda"])}
  }
}