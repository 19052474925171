export default {
  "Flexible Rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sewa Fleksibel"])},
  "Flex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibel"])},
  "Responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Biasanya merespons dalam ", _interpolate(_named("hours")), " jam"])},
  "Typically responds in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Biasanya merespons dalam ", _interpolate(_named("hours")), " jam"])},
  "Full Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang Penuh"])},
  "Coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking"])},
  "Instant Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Instan"])},
  "Payment Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pembayaran"])},
  "Hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Jam"])},
  "Day-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang Hari"])},
  "Hourly pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Per Jam"])},
  "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Harian"])},
  "Flex Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Harian"])},
  "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Bulanan"])},
  "Month Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Bulanan"])},
  "Month pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket Bulanan"])},
  "pax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orang"])},
  "ENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELESAI"])},
  "UPCOMING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AKAN DATANG"])},
  "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan"])},
  "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disetujui"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan"])},
  "canceled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan - Expired"])},
  "canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan oleh Admin"])},
  "canceled_by_guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan oleh Tamu"])},
  "canceled_by_guest_wif_things_considered_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan oleh Tamu"])},
  "canceled_by_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan oleh Host"])},
  "charge_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Gagal"])},
  "charge_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Diproses"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
  "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diproses"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jam"])},
  "hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jam"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hari"])},
  "mth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan"])}
}