export default {
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店主"])},
  "Verify Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证电话号码"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
  "Country Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家代码"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "See Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏详情"])},
  "Show more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
  "Show less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏详情"])},
  "Show Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏详情"])},
  "Show All": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["显示全部(", _interpolate(_named("count")), ")"])},
  "View Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看详情"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "Go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "Credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积分"])},
  "Cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金"])},
  "Promo code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠码"])},
  "Browse Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览房源"])},
  "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空间"])},
  "Message us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
  "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未激活"])},
  "Share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享"])},
  "dashboard": {
    "Welcome back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎回来"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用面板"])},
    "About me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我"])},
    "No resources available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的资源"])},
    "All values shown below are in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["下面显示的所有值都是以", _interpolate(_named("currency")), "为计算单位"])}
  },
  "form": {
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非强制"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必填"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "Select Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
    "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期"])},
    "Start Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始时间"])},
    "Select Date Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期范围"])},
    "Preferred Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首选开始日期"])},
    "End Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束时间"])},
    "Select Lease Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择租赁开始"])},
    "Select Lease End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择租赁结束"])},
    "Cut off date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["截止日期"])},
    "Add a Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一天"])},
    "Add a Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一个月"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "Preferred move in date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首选搬入日期"])},
    "For recurring bookings, click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于重复预订，请点击"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里"])}
  },
  "order": {
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "View Listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看房源"])},
    "View Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看房源"])},
    "View in Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在地图中查看"])},
    "View in Google Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在Google地图中查看"])},
    "Add to Google Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到Google日历"])},
    "View Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看收据"])},
    "Payment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
    "Host Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["店主备注"])}
  },
  "pages": {
    "Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["政策"])}
  },
  "size": {
    "Sq Ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平方英尺"])},
    "Sq M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平方米"])}
  },
  "user": {
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
    "Account pending deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户待删除"])},
    "Verify Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证电子邮件"])},
    "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
    "Sign Up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "Joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入日期"])},
    "Tell us more about yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告诉我们更多关于你自己的信息"])}
  }
}