export default {
  "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "About This Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "Suitable For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适合"])},
  "Includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括"])},
  "All timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下时间均按照"])},
  "Timings are in accordance to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下时间均按照"])},
  "Not available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可预定"])}
}