import { Subject, interval } from 'rxjs';
import { filter, tap, debounce, } from 'rxjs/operators';
// unfocus input  with debounce after a input in specified
const unfocusSubj = new Subject();
/**
 * Unfocus input when a new value is added
 */
export const unfocusOnValue = {
    mounted() {
        unfocusSubj.pipe(filter((binding) => {
            return binding.value !== binding.oldValue;
        }), debounce(({ debounce }) => interval(debounce)), tap(({ el }) => {
            const inputChild = el === null || el === void 0 ? void 0 : el.querySelector('input');
            inputChild === null || inputChild === void 0 ? void 0 : inputChild.blur();
        })).subscribe();
    },
    updated(el, binding) {
        // custom debounce timing
        let bindingDebounceTiming = 500;
        for (const key in binding.modifiers) {
            if (binding.modifiers[key])
                bindingDebounceTiming = (Number(key) || 500);
        }
        unfocusSubj.next(Object.assign(Object.assign({}, binding), { el, debounce: bindingDebounceTiming }));
    },
    beforeUnmount() {
        // unfocusSubj.unsubscribe()
    },
};
