/**
 * List of data to be persisted in localstorage or cookies.
 * Separate out for bigger data to ensure
 * cookie size dont exceed 4kb
 */
export const stateKeyMap = {
    // note: do not change this sequence
    account: {
        user: {
            key: 'user',
            type: 'cookies',
            localStorageType: 'preferences',
            expiry: 12345678,
            skipHydrationRouteNames: []
        },
        userMisc: {
            key: 'userMisc',
            type: 'local',
            expiry: 30,
            skipHydrationRouteNames: []
        }
    },
    flex: {
        booking: {
            key: 'booking',
            type: 'local',
            action: 'booking',
            expiry: null,
            skipHydrationRouteNames: []
        },
        listing: {
            key: 'booking_venue',
            type: 'local',
            action: 'saveVenue',
            expiry: null,
            skipHydrationRouteNames: [
                'Listing', 'Booking', 'Book Success'
            ]
        }
    },
    host: {
        propertyCreate: {
            key: 'central_property_create',
            type: 'local',
            action: 'updateProperty',
            expiry: 30,
            skipHydrationRouteNames: []
        },
        flexCreate: {
            key: 'central_venue_creator',
            type: 'local',
            action: 'updateFlex',
            expiry: 30,
            skipHydrationRouteNames: []
        }
    },
    property: {
        booking: {
            key: 'booking',
            type: 'local',
            action: 'booking',
            expiry: null,
            skipHydrationRouteNames: []
        },
        listing: {
            key: 'listing',
            type: 'local',
            action: 'saveListing',
            expiry: null,
            skipHydrationRouteNames: [
                'Property'
            ]
        }
    },
    searches: {
        searchScrollPos: {
            key: 'search_scroll_pos',
            type: 'cookies',
            expiry: null,
            skipHydrationRouteNames: []
        },
    }
};
