import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { SetMetaPlugin } from 'src/plugins/meta/setMeta';
import { parsePrivatePages } from 'src/plugins/general/privatePages';
import cloneDeep from 'lodash.clonedeep';
/**
 * Standard meta data for GetSpaces
 * @returns
 */
const ModeSwitcher = () => {
    const { StoreLink, StoreMeta, setMetaTitle, setMetaMeta, setMetaLink } = SetMetaPlugin();
    const $route = useRoute();
    const routeName = String($route.name);
    const metaTitle = computed(() => {
        return 'GetSpaces | Rent Spaces By Hour, Great Venues For Every Need';
    });
    const parsedTitle = `${routeName} | ${metaTitle.value}`;
    function switchMode() {
        const linkCopy = cloneDeep(StoreLink.value);
        /******
         * Meta data
         *****/
        // Add unique description here for certain pages
        let desc = `Rent the perfect space on an hourly basis. Empty rooms, classrooms or studios, available in Singapore.
      Find the ideal space for your event from our pool of venues.
      Chat with your host about your event/activity even before you book to ensure memorable experiences.
      Rent a space for as short/long as you like, only paying when your booking is confirmed.
      Have a space to rent out? Its free to list. Available in Singapore.`;
        const routeNameDesc = {
            About: `
        GetSpaces is an online marketplace that connects people
        with the right spaces. Our mission is to build a transparent
        marketplace where people can easily find the spaces they need.
      `,
            'Account Rewards': `
        Earn rewards as you book with GetSpaces! Receive credits
        for your next booking after you complete the booking and leave
        a review
      `,
            'Commercial Property Search': `
        Search for the commercial property you need with a single click.
        Find your ideal property with our hundreds of images. Get your next
        office on GetSpaces today!
      `,
            'Contact Us': `Can't find the space you need? Need help navigating the platform?
        Need custom services? Don't worry! Speak to our friendly customer service and we will be here for you!`,
            'Policies Page': 'Policies and Terms of Use for the GetSpaces Platform',
            Search: `Search for the space you need with a single click.
        Apply our filters to satisfy your needs from our hundreds of unique spaces.
        Book your ideal space in seconds! Spaces by the hour, day or even weekly
      `,
            Sitemap: 'Sitemap for all pages on the GetSpaces Platform',
        };
        if (typeof routeNameDesc[routeName] !== 'undefined') {
            desc = routeNameDesc[routeName];
        }
        const url = `https://getspaces.com${$route.path}`;
        const { privatePages } = parsePrivatePages();
        let metaCopy = cloneDeep(StoreMeta.value);
        metaCopy = Object.assign(Object.assign({}, metaCopy), { 'og:title': Object.assign(Object.assign({}, metaCopy['og:title']), { content: parsedTitle }), 'og:description': Object.assign(Object.assign({}, metaCopy['og:description']), { content: desc }), 'og:url': Object.assign(Object.assign({}, metaCopy['og:url']), { content: url }), 'og:image': Object.assign(Object.assign({}, metaCopy['og:image']), { content: 'https://dminqct125wnz.cloudfront.net/common/default/listing/1280_spaces_default2.jpeg' }), 'og:site_name': Object.assign(Object.assign({}, metaCopy['og:site_name']), { content: 'GetSpaces' }), 'twitter:creator': Object.assign(Object.assign({}, metaCopy['twitter:creator']), { content: '@get_spaces' }), 'twitter:site': Object.assign(Object.assign({}, metaCopy['twitter:site']), { content: '@get_spaces' }), description: Object.assign(Object.assign({}, metaCopy.description), { content: desc }), robots: Object.assign(Object.assign({}, metaCopy.robots), { content: (privatePages.indexOf(routeName) === -1) && (process.env.prodType === 'prod')
                    ? 'all'
                    : 'noindex' }) });
        setMetaTitle(parsedTitle);
        setMetaMeta(metaCopy);
        linkCopy.canonical.href = url;
        /******
         * Icons to be used
         *****/
        const otherIconBaseUrl = 'https://dminqct125wnz.cloudfront.net/spaces/appIcons/icons/favicon-';
        const otherIconList = [96, 16, 32];
        otherIconList.forEach(iconSize => {
            const linkId = `${iconSize}x${iconSize}`;
            linkCopy[linkId] = {
                sizes: linkId,
                type: 'image/png',
                rel: 'icon',
                href: `${otherIconBaseUrl}${linkId}.png`
            };
        });
        linkCopy['180x180'] = {
            href: 'https://dminqct125wnz.cloudfront.net/spaces/appIcons/icons/apple-icon-180x180.png',
            rel: 'apple-touch-icon',
            type: 'image/png',
            sizes: '180x180'
        };
        /******
         * Apple splash screens
         *****/
        const appleImageSizeRatioList = [
            [1136, 640], [2436, 1125], [1792, 828],
            [1334, 750], [2688, 1242], [2208, 1242],
            [2732, 2048], [2224, 1668], [2388, 1668],
            [2048, 1536]
        ];
        const appleImageBaseUrl = 'https://dminqct125wnz.cloudfront.net/spaces/appIcons/splashscreens/apple-launch-';
        appleImageSizeRatioList.forEach(sizeArr => {
            const base = 'apple-touch-startup-image-';
            const linkIdPortrait = `${base}${sizeArr[1]}x${sizeArr[0]}`;
            const linkIdLandscape = `${base}${sizeArr[0]}x${sizeArr[1]}`;
            const portrait = {
                rel: 'apple-touch-startup-image',
                href: `${appleImageBaseUrl}${linkIdPortrait}.png`,
                media: `screen and (device-width: ${sizeArr[1] / 2}px) and (device-height: ${sizeArr[0] / 2}px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)`
            };
            const landscape = {
                rel: 'apple-touch-startup-image',
                href: `${appleImageBaseUrl}${linkIdLandscape}.png`,
                media: `screen and (device-width: ${sizeArr[0] / 2}px) and (device-height: ${sizeArr[1] / 2}px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)`
            };
            linkCopy[linkIdPortrait] = portrait;
            linkCopy[linkIdLandscape] = landscape;
        });
        /******
         * Preload fonts
         *****/
        const fontDict = {
            quicksandMed: 'https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium',
            quicksandBold: 'https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700'
        };
        for (const key in fontDict) {
            const types = ['woff', 'woff2'];
            for (let i = 0; i < types.length; i++) {
                const type = types[i];
                const id = `${key}${type}`;
                if (id in StoreLink)
                    continue;
                linkCopy[id] = {
                    rel: 'preload',
                    as: 'font',
                    href: `${fontDict[key]}.${type}`,
                    type: 'font/woff2',
                    id: id,
                    crossorigin: 'anonymous'
                };
            }
        }
        setMetaLink(linkCopy);
    }
    return {
        switchMode
    };
};
export { ModeSwitcher };
